import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "New Style",
  sku: "sk95",
  availability: "In stock",
  title: "OCSO Stylish Hoody Jacket SK95",
  mrpPrice: "1199",
  sellPrice: "699",
  disc:
    "OCSO Men's Grey solid sleeveless jacket, has a hooded, 2 pockets, zip closure, straight hem, and polyester",
};
export default () => {
  return <ProdLayout data={data} />;
};
